import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";


function LandingFooter() {

    const date = new Date().getFullYear()
    console.log(date)
  return (
    <footer className="landingFooter">
    <div className="footToop">
      <div className="footToopFiest">
        <img src="15.svg" alt="" />
        <p>
          We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat...{" "}
          <button>Read More</button>
        </p>
        <div className="leftSu">
          <AiOutlinePhone />
          312-333-5678
        </div>
        <div className="leftSu">
          <AiOutlineMail />
          pendulumdigitals@gmail.com
        </div>
        <div className="leftSu">
          <CiLocationOn />
          312-333-5678
        </div>
      </div>
      <div className="footToop2 w-full lg:w-2/5 space-y-4 sm:space-y-0 my-4 px-1">
        <ul className="w-full sm:w-[33%]">
          <h2>About</h2>
          <li>About Us</li>
          <li>Blog</li>
          <li>Career</li>
          <li>Jobs</li>
          <li>In Press</li>
          <li>Gallery</li>
          <li>Affiliate</li>
        </ul>
        <ul className="w-full sm:w-[33%]">
          <h2>Support</h2>
          <li>Contact Us</li>
          <li>Online Chat</li>
          <li>Whatsapp </li>
          <li>Telegram</li>
          <li>Ticketing</li>
          <li>Call Center</li>
          <li>Help</li>
        </ul>
        <ul className="w-full sm:w-[33%]">
          <h2>FAQ</h2>
          <li>Account</li>
          <li>Manage Deliveries</li>
          <li>Orders</li>
          <li>Payments</li>
          <li>Returns</li>
          <li>Copyright</li>
          <li>Language</li>
        </ul>
      </div>
    </div>
    <div className="footerBottom">
      <ul>
        <li>About us</li>
        <li>Contact</li>
        <li>Privacy policy</li>
        <li>Sitemap</li>
        <li>Terms of Use</li>
      </ul>
      <p>© {date}, All Rights Reserved</p>
    </div>
  </footer>
  )
}

export default LandingFooter
