import { Link } from 'react-router-dom'

function LandingHeader() {
  return (
    <nav className="landingNav">
    <Link to="/landingpage">
      <img src="/1.png" alt="" />
    </Link>

    <ul className="navLists">
      <li>Pricing</li>
      <li>About</li>
      <li>Our App</li>
      <li>Newsletter</li>
      <li>Demo</li>
      <button>Sign In</button>
    </ul>
  </nav>
  )
}

export default LandingHeader
