import "../assets/css/landing.css";
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import LandingHeroSection from "../components/LandingPage/landingHeroSection";
import LandingHeader from "../components/LandingPage/landingHeader";
import LandingFooter from "../components/LandingPage/landingFooter";

export const Landingpage = () => {
  return (
    <div className="landing">

    <LandingHeader />
    <LandingHeroSection />

      <section className="landingAbout flex justify-between flex-wrap px-5">
    <div className="w-full sm:w-[35%]">
    <img src="/11.svg" alt="" className="landingAboutimg" />
    <img src="/11m.svg" alt="" className="landingAboutimg2" />
    </div>

    <div className="w-full sm:w-[65%] text-center">
    <p>
          <span>Pendulum</span> provides a user-friendly interface that enables
          you to effortlessly manage all activities related to parents,
          teachers, and students.
        </p>
    </div>
      </section>
      <section className="landingHow">
        <h2>How Pendulum works</h2>
        <div className="howContent">
          <div className="howContentCap">
            <div className="howDoll">
              <img src="/4.png" alt="" />
            </div>
            <div className="howText">
              <img src="/5.png" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
          </div>

          <div className="howContentCap2">
            <div className="howText">
              <img src="/21.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/4.png" alt="" />
            </div>
          </div>
          <div className="howContentCap2">
            <div className="howText">
              <img src="/22.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/6.png" alt="" />
            </div>
          </div>
          <div className="howContentCap2 teh">
            <div className="howText">
              <img src="/23.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/3.svg" alt="" />
            </div>
          </div>

          <div className="howContentCap">
            <div className="howText2">
              <img src="/8.png" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll2">
              <img src="/6.png" alt="" />
            </div>
          </div>
          <div className="howContentCap">
            <div className="howText2">
              <img src="/2.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit.
              </p>
            </div>
            <div className="howDoll22">
              <img src="/3.svg" alt="" />
            </div>
            <div className="howText">
              <img src="/1.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="landingWho">
        <h2>Who can use Pendulum?</h2>

        <div className="whoContent">
          <div className="whoContentCap">
            <div className="capContainer">
              <div className="capText">
                <h2>School Admininstrators</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
              <img src="4.svg" alt="" />
            </div>
          </div>
          <div className="whoContentCap">
            <div className="capContainer">
              <img src="5.svg" alt="" />
              <div className="capText2">
                <h2>Teachers</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
            </div>

            <p>
              “Our platform facilitates the efficient and effective management
              of activities involving parents, teachers, and students with ease
              and simplicity.”
            </p>
            <button>Explore our Feature</button>
          </div>
          <div className="whoContentCap">
            <div className="capContainer">
              <div className="capText">
                <h2>Parent and Children</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
              <img src="6.svg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="testimony">
        <FaQuoteLeft className="tesIcon" />
        <p>
          Our school has been using this platform for a while now, and it has
          really helped us improve our overall performance. We can now easily
          monitor student progress and identify areas for improvement.
        </p>
        <FaQuoteRight className="tesIcon" />
      </section>
      <section className="landingDemo">
        <div className="demoCon">
          <img src="7.svg" alt="" />
          <button>Book a Demo</button>
        </div>
        <img src="9.svg" alt="" className="bac" />
      </section>

      <section className="newsletter">
        <div className="newsText">
          <h3>Join our Newsletter</h3>
          <p>Be the first to know. Be the first to benefit.</p>
          <input type="text" placeholder="Enter Name" />
          <input type="email" placeholder="Enter Email Address" />
        </div>

        <button>Subscribe</button>

        <div className="secondImg">
          <img src="8.svg" alt="" />
        </div>
      </section>

      <section className="download">
        <div className="downloadOut">
          <div className="downloadIn">
            <h2>Download our App</h2>

            <div className="downloadCont">
              <img src="10.svg" alt="" />
              <div className="downloadContText">
                <h3>Our App Solves All School Management Issues</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur.</li>
                  <li>Lorem ipsum dolor si.</li>
                  <li>Lorem ipsum.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LandingFooter />
    </div>
  );
};
