import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { AdminDashboard } from "./pages/AdminDashboard";
import { Login } from "./pages/Login";
import { RegisterStudent } from "./pages/RegisterStudent";
import { RegisterTeacher } from "./pages/RegisterTeacher";
import { RegisterClass } from "./pages/RegisterClass";
import "./assets/css/main.css";
import { AdminHome } from "./pages/AdminHome";
import { Attendance } from "./pages/Attendance";
import { Contact } from "./pages/Contact";
import { Message } from "./pages/Message";
import { BroadcastMessage } from "./pages/BroadcastMessage";
import { MessageBox } from "./pages/MessageBox";
import ClassActivities from "./pages/ClassActivities";
import { CreateQuiz } from "./pages/CreateQuiz";
import { CreateImportQuiz } from "./pages/CreateImportQuiz";
import { CreateQuizOptions } from "./pages/CreateQuizOption";
import { CreateQuizTick } from "./pages/CreateQuizTick";
import { ClassActivitiesLayout } from "./layouts/ClassActivitiesLayout";
import { Student } from "./pages/Student";
import { Staff } from "./pages/Staff";
import { LessonNote } from "./pages/LessonNote";
import { Teacher } from "./pages/teacher/Teacher";
import { Class } from "./pages/Class";
import { LessonNoteUpload } from "./pages/LessonNoteUpload";
import { StaffData } from "./pages/StaffData";
import { StudentData } from "./pages/StudentData";
import { Classes } from "./pages/Classes";
import { AddClass } from "./pages/AddClass";
import { ClassSubject } from "./pages/ClassSubject";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Landingpage } from "./pages/landingPage";
import PV from "./pages/Photo&Video";
import Billing from "./pages/billing";
import Account from "./pages/Account";
import Events from "./pages/EventAndCalendar";
import { StudentActivityFeed } from "./pages/StudentActivityFeed";
import { StaffStudentFeed } from "./staff_pages/StudentActivityFeed";
import { Cloudinary } from "@cloudinary/url-gen";
import { Toaster } from "react-hot-toast";
import Report from "./pages/Report";
import Birthday from "./pages/birthday";
import ContactReport from "./pages/contactReport";
import { Dashboard } from "./staff_pages/Dashboard";
import { Home } from "./staff_pages/Home";
import StaffEvents from "./staff_pages/StaffEvents";
import SchProfile from "./pages/setting/schollProfile";
import { SettingLayout } from "./layouts/SettingLayout";
import PaymentOnboarding from "./pages/setting/paymentOnboarding";
import { SelectReceiver } from "./pages/SelectReceiver";
import { useState, useEffect } from "react";
import Pusher from "pusher-js";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { store } from "./app/store";
import { openConversationThunk } from "./features/actions";
import { AuthurizePickup } from "./pages/AuthorizePickUp";
import { CAlogin } from "./pages/classActivities/CAlogin";
import { CAactivities } from "./pages/classActivities/CAactivities";
import { CAhealth } from "./pages/classActivities/CAhealth";
import { CAtemeperature } from "./pages/classActivities/CAtemperature";
import { CAfood } from "./pages/classActivities/CAfood";
import { CAliquid } from "./pages/classActivities/CAliquid";
import { CAattitude } from "./pages/classActivities/CAattitude";
import { CAsleep } from "./pages/classActivities/CAsleep";
import { CAtoilet } from "./pages/classActivities/CAtoilet";
import { CAnotes } from "./pages/classActivities/CAnotes";
import { CAsport } from "./pages/classActivities/CAsport";
import { CAsupplies } from "./pages/classActivities/CAsupplies";
import { CAlogout } from "./pages/classActivities/CAlogout";

// PENDULULM ADMIN
import AddSchool from "./pages/pendulum/AddSchool";
import PendulumAdminDashboard from "./pages/pendulum/AdminDashboard";
import EditSchool from "./pages/pendulum/EditSchool";
import Suspend from "./pages/pendulum/Suspend";
import UserData from "./pages/pendulum/UserData";
import PendulumAdminLayout from "./pages/pendulum/PendulumAdminLayout";

import Faq from "./components/faq";
import ChanPasssord from "./components/chanPasssord";
import { StaffStudentData } from "./staff_pages/Staff_StudentData";
import Archive from "./pages/Archive";
import { EnrollmentSupport } from "./pages/EnrollmentSupport";
export let { log } = console;

// Create a Cloudinary instance and set your cloud name.
export const cld = new Cloudinary({
  cloud: {
    cloudName: "dbfdhrz8m",
  },
});

function App() {
  let dispatch = useAppDispatch();

  const [chat, setChat] = useState<any>([]);

  const pusherApi = process.env.PUSHER_API_KEY || "d4e25516add86a898613";
  const pusher = new Pusher(pusherApi, {
    cluster: "mt1",
  });

  useEffect(() => {
    const channel = pusher.subscribe("message");
    // Update chat
    channel.bind("NewMessageEvent", function (data: any) {
      let messages = store.getState().message as any;
      let convo = messages.convo;
      dispatch(openConversationThunk(convo));
    });

    return () => {
      pusher.unsubscribe("message");
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/landingpage" element={<Landingpage />} />

      {/* ADMIN ROUTES */}
        <Route path="/admin" element={<AdminDashboard />}>
          <Route index element={<AdminHome />} />
          <Route
            path="child-activities/:pupil_id"
            element={<StudentActivityFeed />}
          />
          <Route path="attendance" element={<Attendance />} />
          <Route path="student" element={<Student />} />
          <Route path="student/register" element={<RegisterStudent />} />
          <Route path="student/student_data" element={<StudentData />} />
          <Route path="staff" element={<Staff />} />
          <Route path="staff/lesson_note" element={<LessonNote />} />
          <Route
            path="staff/lesson_note/upload"
            element={<LessonNoteUpload />}
          />
          <Route path="staff/staff_data" element={<StaffData />} />
          <Route path="staff/register" element={<RegisterTeacher />} />
          <Route path="contact" element={<Contact />} />
          <Route path="message" element={<Message />} />
          <Route path="message/broadcast" element={<BroadcastMessage />} />
          <Route
            path="message/messagebox/:conversationID"
            element={<MessageBox chat={chat} />}
          />
          <Route path="message/messagepick" element={<SelectReceiver />} />
          <Route path="class" element={<Class />} />
          <Route path="class/classes" element={<Classes />} />
          <Route path="class/add" element={<AddClass />} />
          <Route path="class/subject" element={<ClassSubject />} />
          <Route path="class/register" element={<RegisterClass />} />
          <Route path="class/quiz" element={<CreateQuiz />} />
          <Route path="class/quiz/import" element={<CreateImportQuiz />} />
          <Route path="class/quiz/options" element={<CreateQuizOptions />} />
          <Route path="class/quiz/ticks" element={<CreateQuizTick />} />
          <Route path="class/activities" element={<ClassActivities />} />
          <Route path="class/activities" element={<ClassActivitiesLayout />}>
            <Route path="login" element={<CAlogin />} />
            <Route path="activities" element={<CAactivities />} />
            <Route path="health" element={<CAhealth />} />
            <Route path="temperature" element={<CAtemeperature />} />
            <Route path="food" element={<CAfood />} />
            <Route path="liquid" element={<CAliquid />} />
            <Route path="Mood" element={<CAattitude />} />
            <Route path="sleep" element={<CAsleep />} />
            <Route path="toilet" element={<CAtoilet />} />
            <Route path="notes" element={<CAnotes />} />
            <Route path="sport" element={<CAsport />} />
            <Route path="supplies" element={<CAsupplies />} />
            <Route path="logout" element={<CAlogout />} />
          </Route>
          <Route path="teacher" element={<Teacher />} />
          <Route path="pv" element={<PV />} />
          <Route path="billing" element={<Billing />} />
          <Route path="archive" element={<Archive />} />
          <Route path="report" element={<Report />} />
          <Route path="birthday" element={<Birthday />} />
          <Route path="contactreport" element={<ContactReport />} />
          <Route path="setting" element={<SchProfile />} />
          <Route path="setting" element={<SettingLayout />}>
            <Route path="paymentonboarding" element={<PaymentOnboarding />} />
          </Route>
          <Route path="account" element={<Account />} />
          <Route path="events" element={<Events />} />
          <Route path="authorizepickup" element={<AuthurizePickup />} />
          <Route path="enrollment" element={<EnrollmentSupport />}  />
          {/* <Route path="allevent" element={<AllEvent />} /> */}
          {/* <Route path="allevent" element={<AllEvent />} /> */}
        </Route>


{/* STAFF ROUTE */}
        <Route path="/staff" element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="student/student_data" element={<StaffStudentData />} />
          {/* <Route path="staff/lesson_note" element={<LessonNote />} /> */}
          <Route path="authorizepickup" element={<AuthurizePickup />} />
          <Route path="feedback" element={<Faq />} />
          <Route path="changePassword" element={<ChanPasssord />} />

          <Route
            path="staff/lesson_note/upload"
            element={<LessonNoteUpload />}
          />
          <Route path="contact" element={<Contact />} />
          <Route path="message" element={<Message />} />
          <Route path="message/broadcast" element={<BroadcastMessage />} />
          <Route path="message/messagebox/:conversationID" element={<MessageBox />} />
          <Route path="message/messagepick" element={<SelectReceiver />} />
          <Route path="class" element={<Class />} />
          <Route path="class/classes" element={<Classes />} />
          <Route path="class/add" element={<AddClass />} />
          <Route path="class/subject" element={<ClassSubject />} />
          <Route path="class/activities" element={<ClassActivities />} />



          <Route path="class/activities" element={<ClassActivitiesLayout />}>
            <Route path="login" element={<CAlogin />} />
            <Route path="activities" element={<CAactivities />} />
            <Route path="health" element={<CAhealth />} />
            <Route path="temperature" element={<CAtemeperature />} />
            <Route path="food" element={<CAfood />} />
            <Route path="liquid" element={<CAliquid />} />
            <Route path="Mood" element={<CAattitude />} />
            <Route path="sleep" element={<CAsleep />} />
            <Route path="toilet" element={<CAtoilet />} />
            <Route path="notes" element={<CAnotes />} />
            <Route path="sport" element={<CAsport />} />
            <Route path="supplies" element={<CAsupplies />} />
            <Route path="logout" element={<CAlogout />} />
          </Route>
          <Route
            path="child-activities/:pupil_id"
            element={<StaffStudentFeed />}
          />
          {/* <Route path="teacher" element={<Teacher />} /> */}
          <Route path="pv" element={<PV />} />
          <Route path="events" element={<StaffEvents />} />
        </Route>
        <Route path="/child" element={<AdminDashboard />}>
          <Route path="feeds" element={<StudentActivityFeed />} />
          <Route path="feed" element={<StaffStudentFeed />} />
        </Route>

        <Route path="/pendulum" element={<PendulumAdminLayout />}>
          <Route path="" element={<PendulumAdminDashboard />} />
          <Route path="add-school" element={<AddSchool />} />
          <Route path="users" element={<UserData />} />
          <Route path="suspend" element={<Suspend />} />
          <Route path="edit-school/:id" element={<EditSchool />} />
        </Route>
      </Routes>

      {/* <Routess /> */}
      <Toaster
        toastOptions={{
          className: "w-[400px] h-[50px]",
          style: {
            background: "#f1f1f1",
            color: "#1f1f1f",
            fontSize: "16px",
          },
        }}
      />
    </Router>
  );
}

export default App;
