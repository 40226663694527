function LandingHeroSection() {
  return (
    <header className="landingheader">
    <div className="headerContainer">
      <div className="landingHeaderText">
        <h2>
          {" "}
          Digitalizing school operations, including learning and
          administration.
        </h2>
        <p>
          The adoption of digital tools and platforms to transform learning
          and administrative processes in schools
        </p>

        <div className="landingHeaderBtn">
          <button className="headerBtn1">Book a demo</button>
          <button className="headerBtn2">Learn how it works</button>
        </div>
        <div className="extraText">
          <span>“</span>
          <p>
            Monitoring and evaluation are essential for ensuring that
            education policies and programs are effective, efficient, and
            equitable.
          </p>
          <span>“</span>
        </div>
      </div>

      <div className="landingHeaderPic">
        <img src="2.png" alt="" />
        <div className="extraText">
          <span>“</span>
          <p>Measure progress, achieve success.</p>
          <span>“</span>
        </div>
      </div>
    </div>
    <div className="landingHeaderBtn2">
      <button className="headerBtn1">Book a demo</button>
      <button className="headerBtn2">Learn how it works</button>
    </div>
  </header>
  )
}

export default LandingHeroSection
